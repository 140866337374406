import React from 'react'
import {
  Drawer, Button,
  Form, FormGroup, ControlLabel, FormControl,
  Panel, PanelGroup,
  Icon,
} from 'rsuite'
import { GetAvatar } from '../../helpers/Utils'
import DropZone from '../common/DropZone'

const ContentComponent = ({
  i18n,
  open,
  toggle,
  onSubmit,
  onChange,
  values,
}) => {
  return (
    <Drawer
      size="xs"
      show={open}
      onHide={toggle}
    >
      <Drawer.Header>
        <Drawer.Title>{i18n.messages['profile.title']}</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>

        {/* FORM PROFILE */}
        <Form fluid>
          <FormGroup>
            <ControlLabel>{i18n.messages['account.form.full_name']}</ControlLabel>
            <FormControl
              onKeyPress={e => {
                if (e.key === 'Enter')
                  onSubmit('update')
              }}
              value={values.full_name}
              onChange={onChange}
              name="full_name" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{i18n.messages['account.headerTable.email']}</ControlLabel>
            <FormControl
              onKeyPress={e => {
                if (e.key === 'Enter')
                  onSubmit('update')
              }}
              value={values.email}
              onChange={onChange}
              name="email" />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{i18n.messages['account.address']}</ControlLabel>
            <FormControl
              onKeyPress={e => {
                if (e.key === 'Enter')
                  onSubmit('update')
              }}
              value={values.address}
              onChange={onChange}
              name="address" />
          </FormGroup>

          <FormGroup>
            <ControlLabel>
              {i18n.messages['product.image']}
              {values.avatar_img_path ? (
                <span style={{ cursor: "pointer", marginLeft: 10 }} onClick={() => { onSubmit('remove-image') }}> <Icon icon="edit2" /> </span>
              ) : null}
            </ControlLabel>
            {values.avatar_img_path ? (
              <div className="modal-wrapper-img">
                <img src={GetAvatar(values.avatar_img_path)} alt={'image'} />
              </div>
            ) : (
                <DropZone
                  folder="users"
                  maxFiles={1}
                />
              )}
          </FormGroup>

          <PanelGroup accordion bordered>
            <Panel header={i18n.messages['account.password']}>
              <FormGroup>
                <ControlLabel>{i18n.messages['account.oldpassword']}</ControlLabel>
                <FormControl
                  onKeyPress={e => {
                    if (e.key === 'Enter')
                      onSubmit('update')
                  }}
                  type="password"
                  value={values.oldPassword}
                  onChange={onChange}
                  name="oldPassword" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>{i18n.messages['account.password']}</ControlLabel>
                <FormControl
                  onKeyPress={e => {
                    if (e.key === 'Enter')
                      onSubmit('update')
                  }}
                  type="password"
                  value={values.password}
                  onChange={onChange}
                  name="password" />
              </FormGroup>
              <FormGroup>
                <ControlLabel>{i18n.messages['account.repassword']}</ControlLabel>
                <FormControl
                  onKeyPress={e => {
                    if (e.key === 'Enter')
                      onSubmit('update')
                  }}
                  type="password"
                  value={values.confirmPassword}
                  onChange={onChange}
                  name="confirmPassword" />
              </FormGroup>
            </Panel>
          </PanelGroup>
        </Form>

      </Drawer.Body>
      <Drawer.Footer>
        <Button onClick={toggle} appearance="subtle">
          {i18n.messages['button.action.cancel']}
        </Button>
        <Button onClick={e => {onSubmit('update')}} appearance="primary">
          {i18n.messages['button.action.done']}
        </Button>
      </Drawer.Footer>
    </Drawer>
  )
}
export default ContentComponent;