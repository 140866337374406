
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Nav,
  Icon, Dropdown,
} from 'rsuite'
import classnames from 'classnames'
import menuItems from './menu'
import { getPermissionCode } from '../../helpers/Utils'

const CustomNav = (props) => {
  const [state, setState] = useState({
    permission: getPermissionCode(),
    pathname: "",
    title: "",
  })

  useEffect(
    () => {
      setState({
        ...state,
        pathname: window.location.pathname
      })
    }, [])

  const GetTitle = () => {
    const find_link = menuItems.find(_link => _link.to === state.pathname);

    if (find_link)
      return find_link.icon ? <Icon icon={find_link.icon} size="2x" /> : find_link.title;

    return "";
  }

  return (
    <section className="hamburger-icon">
      <header className="header container">
        <span className="logo">{state.title}</span>
        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
        <ul className="custom-hamburger menu">
          <nav className="nav" role="navigation">
            <ul className="nav__list">
              {menuItems.map((item, idx) => {
                return (
                  item.subs
                    ?
                    <li key={idx}>
                      <input id={idx} type="checkbox" hidden />
                      <label htmlFor={idx}><span><Icon icon="caret-right" /></span>
                        {item.title}
                      </label>
                      <ul className="group-list">
                        {item.subs.map((sub, idx2) => {
                          return (
                            state.permission >= sub.minPermission
                            && <li
                              key={idx2}
                              onClick={() => {
                                setState({
                                  ...state,
                                  pathname: item.to,
                                  title: sub.title,
                                })
                              }}>
                              <NavLink to={sub.to}> {sub.title}</NavLink>
                            </li>
                          )
                        })}
                      </ul>
                    </li>
                    :
                    state.permission >= item.minPermission && <li
                      key={idx}
                      onClick={() => {
                        setState({
                          ...state,
                          pathname: item.to,
                          title: item.icon ? <Icon icon={item.icon} size="2x" />: item.title,
                        })
                      }}>
                      <NavLink to={item.to}>
                        {item.icon ? <Icon icon={item.icon} /> : item.title}
                      </NavLink>
                    </li>
                )
              })}
            </ul>
          </nav>
        </ul>
      </header>
    </section >
  )
}

export default CustomNav;
