import { all } from 'redux-saga/effects'

import { default as AccountSaga } from './Account/sagas';
import { default as ProductSaga } from './Product/sagas';
import { default as ProductCategorySaga } from './ProductCategory/sagas';
import { default as ProductStatusSaga } from './ProductStatus/sagas';
import { default as EventSaga } from './Event/sagas';
import { default as WarehouseSaga } from './Warehouse/sagas';
import { default as ChatSaga } from './Chat/sagas';

export default function* rootSaga() {
    yield all([
       AccountSaga(),
       ProductSaga(),
       ProductCategorySaga(),
       ProductStatusSaga(),
       EventSaga(),
       WarehouseSaga(),
       ChatSaga(),
    ])
}
