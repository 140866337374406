import React, { Component } from "react"
import Toolbar from '../components/toolbar/Toolbar';
import Nav from '../components/nav/Nav';
import Marquee from "../components/toolbar/Marquee";
import {
  Container,
  Header, Footer,
  Content, Sidebar,
  Row, Col, Grid,
} from 'rsuite'
import { withRouter } from "react-router-dom";
import ErrorBoundary from '../components/common/ErrorBoundary'

class UserLayout extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  render() {
    const isMobile = this.state.screenWidth <= 1200;
    localStorage.setItem("isMobile", isMobile);

    return (
      <div id="app-container">
        <header className="toolbar">
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
        </header>
        <div>
          <Nav />
        </div>
        <div className="body-overview min-h-500">
          <ErrorBoundary>
            <Container>
              <Content>
                <Marquee />
                <div>
                  {this.props.children}
                </div>
              </Content>
            </Container>
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

export default withRouter(UserLayout)