import * as TYPE from './types'

// QRCODE
const dispatchQRCodeCheckinAsync = (data) => ({
  type: TYPE.QRCODE_CHECKIN,
  data,
});
const dispatchQRCodeCheckoutAsync = (data) => ({
  type: TYPE.QRCODE_CHECKOUT,
  data,
});

// CRUD
const dispatchProductGetCountAsync = (data) => ({
  type: TYPE.PRODUCT_GET_COUNT,
  data,
});
const dispatchProductGetDetailAsync = (data) => ({
  type: TYPE.PRODUCT_GET_DETAIL,
  data,
});
const dispatchProductCreateAsync = (data) => ({
  type: TYPE.PRODUCT_CREATE,
  data,
});
const dispatchProductListAsync = () => ({
  type: TYPE.PRODUCT_LIST,
});
const dispatchProductUpdateAsync = (data) => ({
  type: TYPE.PRODUCT_UPDATE,
  data,
});
const dispatchProductDeleteAsync = (data) => ({
  type: TYPE.PRODUCT_DELETE,
  data,
});

// NOTE
const dispatchProductVerifyNoteAsync = (data) => ({
  type: TYPE.PRODUCT_VERIFY_NOTE,
  data,
});

export default {
  dispatchQRCodeCheckinAsync,
  dispatchQRCodeCheckoutAsync,
  
  dispatchProductGetCountAsync,
  dispatchProductGetDetailAsync,
  dispatchProductCreateAsync,
  dispatchProductListAsync,
  dispatchProductUpdateAsync,
  dispatchProductDeleteAsync,

  dispatchProductVerifyNoteAsync,
};

// action 
export const QRCodeCheckinAsync = (payload) => {
  return {
    type: TYPE.QRCODE_CHECKIN_ASYNC,
    payload
  }
} 
export const QRCodeCheckoutAsync = (payload) => {
  return {
    type: TYPE.QRCODE_CHECKOUT_ASYNC,
    payload
  }
} 

export const GetCountAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_GET_COUNT_ASYNC,
    payload
  }
} 
export const GetDetailAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_GET_DETAIL_ASYNC,
    payload
  }
} 
export const ListAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_DELETE_ASYNC,
    payload
  }
}

export const VerifyNoteAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_VERIFY_NOTE_ASYNC,
    payload
  }
}
