import * as TYPE from './types'

const dispatchLoginAsync = (user, history) => ({
  type: TYPE.ACCOUNT_LOGIN,
  user,
  history,
});
const dispatchLogoutAsync = (history) => ({
  type: TYPE.ACCOUNT_LOGOUT,
  history,
});
const dispatchAccountCreateAsync = (user) => ({
  type: TYPE.ACCOUNT_CREATE,
  user,
});
const dispatchAccountUpdateAsync = (user) => ({
  type: TYPE.ACCOUNT_UPDATE,
  user,
});
const dispatchAccountDeleteAsync = (user) => ({
  type: TYPE.ACCOUNT_DELETE,
  user,
});
const dispatchAccountListAsync = () => ({
  type: TYPE.ACCOUNT_LIST,
});
const dispatchAccountUpdateMeAsync = (user) => ({
  type: TYPE.ACCOUNT_UPDATE_ME,
  user,
});

// online 
const dispatchAccountCountOnlineAsync = (user) => ({
  type: TYPE.ACCOUNT_COUNT_ONLINE,
  user,
});

export default {
  dispatchLoginAsync,
  dispatchLogoutAsync,
  dispatchAccountCreateAsync,
  dispatchAccountUpdateAsync,
  dispatchAccountListAsync,
  dispatchAccountDeleteAsync,
  dispatchAccountUpdateMeAsync,
  dispatchAccountCountOnlineAsync,
};

export const LoginAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LOGIN_ASYNC,
    payload
  }
}
export const CreateAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_CREATE_ASYNC,
    payload
  }
}
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_UPDATE_ASYNC,
    payload
  }
}
export const ListAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_LIST_ASYNC,
    payload
  }
}
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_DELETE_ASYNC,
    payload
  }
}
export const UpdateMeAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_UPDATE_ME_ASYNC,
    payload
  }
}

export const CountOnlineAsync = (payload) => {
  return {
    type: TYPE.ACCOUNT_COUNT_ONLINE_ASYNC,
    payload
  }
}