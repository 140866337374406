import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCreate(data) {
  const { data: { code, name } } = data;
  return axios.post(Url.END_POINT + "/api/create_categories",
    {
      name,
      code,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: { id, name, code, } } = data;
  return axios.put(Url.END_POINT + "/api/update_categories",
    {
      id_categories: id,
      name,
      code,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList() {
  return axios.get(Url.END_POINT + "/api/get_all_user_product_categories",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { id } } = data;
  return axios.delete(Url.END_POINT + "/api/delete_categories",
    {
      data: {
        id_categories: id
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}


/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionList() {
  const response = yield call(asyncList);

  // console.log(response); return;
  let result = {}
  result.message = "Có lỗi xảy ra";

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;

    if (response.status === 200) {
      let { Notification: { User_product_categories } } = response.data;
      result.data = User_product_categories;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
    
    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.DeleteAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.PRODUCT_CATEGORY_CREATE, actionCreate)
  yield takeLatest(TYPE.PRODUCT_CATEGORY_LIST, actionList)
  yield takeLatest(TYPE.PRODUCT_CATEGORY_UPDATE, actionUpdate)
  yield takeLatest(TYPE.PRODUCT_CATEGORY_DELETE, actionDelete)
}

export default CustomSaga