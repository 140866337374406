// QRCODE
export const QRCODE_CHECKIN = "QRCODE_CHECKIN"
export const QRCODE_CHECKIN_ASYNC = "QRCODE_CHECKIN_ASYNC"
export const QRCODE_CHECKOUT = "QRCODE_CHECKOUT"
export const QRCODE_CHECKOUT_ASYNC = "QRCODE_CHECKOUT_ASYNC"

// CRUD
export const PRODUCT_GET_DETAIL = "PRODUCT_GET_DETAIL"
export const PRODUCT_GET_DETAIL_ASYNC = "PRODUCT_GET_DETAIL_ASYNC"
export const PRODUCT_CREATE = "PRODUCT_CREATE"
export const PRODUCT_CREATE_ASYNC = "PRODUCT_CREATE_ASYNC"
export const PRODUCT_UPDATE = "PRODUCT_UPDATE"
export const PRODUCT_UPDATE_ASYNC = "PRODUCT_UPDATE_ASYNC"
export const PRODUCT_LIST = "PRODUCT_LIST"
export const PRODUCT_LIST_ASYNC = "PRODUCT_LIST_ASYNC"
export const PRODUCT_DELETE = "PRODUCT_DELETE"
export const PRODUCT_DELETE_ASYNC = "PRODUCT_DELETE_ASYNC"

// VERIFIER NOTE
export const PRODUCT_VERIFY_NOTE = "PRODUCT_VERIFY_NOTE"
export const PRODUCT_VERIFY_NOTE_ASYNC = "PRODUCT_VERIFY_NOTE_ASYNC"

// GET COUNT
export const PRODUCT_GET_COUNT = "PRODUCT_GET_COUNT"
export const PRODUCT_GET_COUNT_ASYNC = "PRODUCT_GET_COUNT_ASYNC"