import React, { useState, useEffect } from 'react';
import {
  Input,
  Dropdown,
  Icon,
} from 'rsuite'
import Profile from './Profile'
import { ConnectedComponent as connect } from '../../constants'
import DrawerToggleButton from '../sideDrawer/DrawerToggleButton';
import { NavLink, Link } from 'react-router-dom'
import { isValidateEmail } from '../../helpers/Utils'
import { toast } from 'react-toastify'

const toolbar = props => {
  const [state, setState] = useState({
    open_drawer: false,
    is_submit: false,
    profile_form: {
      full_name: "",
      address: "",
      email: "",
      avatar_img_path: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    keyword: "",
  })

  useEffect(
    () => {
      if (state.is_submit) {
        // 
        if (props.accounts.messageUpdateMe && props.accounts.messageUpdateMe.length) {
          if (props.accounts.statusUpdateMe === 200) {
            toast.info(props.accounts.messageUpdateMe);
          } else {
            toast.error(props.accounts.messageUpdateMe);
          }
        }
      }
    }, [props.accounts.messageUpdateMe, state.is_submit])

  const handleOnClickLogout = e => {
    props.dispatchLogoutAsync();
  }
  const handleChangeValue = val => {
    setState({
      ...state,
      keyword: val
    })
  }

  // Drawer
  const onHandleToggleDrawer = () => {
    if (state.open_drawer) {
      // close
      setState({
        ...state,
        open_drawer: !state.open_drawer
      })
    }
    else {
      // open -> update
      setState({
        ...state,
        open_drawer: !state.open_drawer,
        profile_form: {
          ...state.profile_form,
          full_name: state.profile_form.full_name || localStorage.getItem('full_name'),
          email: state.profile_form.email || localStorage.getItem('email'),
          address: state.profile_form.address || localStorage.getItem('address'),
          avatar_img_path: state.profile_form.avatar_img_path || localStorage.getItem('avatar_img_path'),
        }
      })
    }
  }
  const onSubmitDrawer = (type) => {
    if (type === 'update') {
      // validate
      let { email, full_name, address, avatar_img_path, oldPassword, password, confirmPassword } = state.profile_form;

      if (password !== confirmPassword) {
        toast.error(props.i18n.messages["toast.error.confirmPassword"]);
        return;
      }

      if (!full_name || !email || !address) {
        toast.error(props.i18n.messages["toast.error.required"]);
        return;
      }

      if (full_name.length < 5) {
        toast.error(props.i18n.messages["toast.error.fullnameLeast5"]);
        return;
      }

      if (address.length < 5) {
        toast.error(props.i18n.messages["toast.error.addressLeast5"]);
        return;
      }

      if (!isValidateEmail(email)) {
        toast.error(props.i18n.messages["toast.error.emailFormat"]);
        return;
      }
      
      if (oldPassword !== "" && password !== "" && confirmPassword !== "") {
        if (oldPassword.length < 5 || password.length < 5 || confirmPassword.length < 5) {
          toast.error(props.i18n.messages["toast.error.passwordLeast6"]);
          return;
        }
      }
      
      // avatar_img_path
      if (!avatar_img_path) {
        let images = localStorage.getItem("uploads");
        if (!images) {
          return toast.error(props.i18n.messages['toast.error.required.image']);
        }
        else {
          avatar_img_path = images;
        }
      }

      props.dispatchAccountUpdateMeAsync({
        full_name: state.profile_form.full_name,
        email: state.profile_form.email,
        address: state.profile_form.address,
        image: avatar_img_path,
        oldPassword: state.profile_form.oldPassword,
        password: state.profile_form.password,
        confirmPassword: state.profile_form.confirmPassword,
      });

      setState({
        ...state,
        open_drawer: !state.open_drawer,
        is_submit: true,
      })
    }
    if (type === 'remove-image') {
      setState({
        ...state,
        profile_form: {
          ...state.profile_form,
          avatar_img_path: "",
        }
      })
      return "";
    }

    localStorage.removeItem('uploads')
    return "";
  }
  const onChangeDrawer = (val, e) => {
    const { target: { name, value } } = e;

    setState({
      ...state,
      is_submit: false,
      profile_form: {
        ...state.profile_form,
        [name]: value
      }
    })
  }
  // End Drawer

  return (
    <nav className="toolbar__navigation">
      {/* <div className="toolbar__toggle-button">
        <DrawerToggleButton click={props.drawerClickHandler}/>
      </div> */}
      <Link className="side-drawer-logo" to="/app/dashboards/default" />
      <div className="toolbar__search">
        {/* <label>Tìm kiếm</label> */}
        <div>
          <Input onChange={handleChangeValue} value={state.keyword} />
          <Link
            to={{
              pathname: '/app/search/default',
              aboutProps: {
                keyword: state.keyword
              }
            }}
          >
            <span onClick={() => {
              setState({
                ...state,
                keyword: "",
              })
            }} />
          </Link>
        </div>
      </div>
      <div className="toolbar__qr">
        <a href="/app/qrcode/default">
          <span className="toolbar__qr__icon" />
        </a>
      </div>
      <div className="spacer" />
      <Profile
        i18n={props.i18n}
        open={state.open_drawer}
        toggle={onHandleToggleDrawer}
        onSubmit={onSubmitDrawer}
        onChange={onChangeDrawer}
        values={state.profile_form}
      />
      <div className="toolbar_navigation-items">
        <Dropdown
          renderTitle={() => {
            return <div className="toolbar_navigation-items__setting" />;
          }}
          placement="bottomEnd"
        >
          <Dropdown.Item
            onClick={onHandleToggleDrawer}
            style={{ minWidth: 160 }} icon={<Icon icon="user-circle-o" />}>
            {props.i18n.messages['profile.title']}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleOnClickLogout}
            style={{ minWidth: 160 }} icon={<Icon icon="exit" />}>
            {props.i18n.messages['toolbar.logout']}
          </Dropdown.Item>
        </Dropdown>
      </div>
    </nav>
  )
}

export default connect(toolbar)
