import React from 'react';
import {
  ConnectedComponent as connect,
} from '../../constants'

class NotFound extends React.Component {

  render() {
    return (
      <p>Opps. Page not found </p>
    )
  }
}

export default connect(NotFound)