import * as TYPE from './types'

const initialState = {
  data: [],
  dataQRcode: undefined,
  messageQRcode: "",
  statusQRcode: 500,
  dataDashboard: [],
  message: "",
  status: 500,
}

function ProductReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.QRCODE_CHECKIN_ASYNC:
      return {
        ...state,
        messageQRcode: action.payload.message,
        statusQRcode: action.payload.status,
      }
    case TYPE.QRCODE_CHECKOUT_ASYNC:
      return {
        ...state,
        messageQRcode: action.payload.message,
        statusQRcode: action.payload.status,
      }
    case TYPE.PRODUCT_GET_DETAIL_ASYNC:
      return {
        ...state,
        dataQRcode: action.payload.data,
        messageQRcode: action.payload.message,
        statusQRcode: action.payload.status,
      }
    case TYPE.PRODUCT_GET_COUNT_ASYNC:
      return {
        ...state,
        dataDashboard: action.payload.data,
      }

    case TYPE.PRODUCT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }

    case TYPE.PRODUCT_VERIFY_NOTE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default ProductReducer