import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCreate(data) {
  const { data: { uuid, name, agency, salary, location, date_start, time_start, date_end, time_end } } = data;
  return axios.post(Url.END_POINT + "/api/create_event",
    {
      name,
      agency,
      location,
      salary,
      date_start,
      time_start,
      date_end,
      time_end,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList(data) {
  return axios.get(Url.END_POINT + "/api/get_all_events_and_users",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListProduct(data) {
  const { data: { uuid } } = data;
  return axios.get(Url.END_POINT + "/api/get_product_in_event/" + uuid,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListProductTemp(data) {
  const { data: { uuid } } = data;
  return axios.get(Url.END_POINT + "/api/get_product_checked_in_event/" + uuid,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdateProductTemp(data) {
  const { data: { product_uuid, event_uuid } } = data;
  return axios.post(Url.END_POINT + "/api/create_product_event_temp",
    {
      product_uuid, //[]
      event_uuid,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: { uuid, name, agency, salary, location, date_start, time_start, date_end, time_end } } = data;
  let dataBody = {
    uuid_event: uuid,
    date_start: date_start,
    time_start: time_start,
    date_end: date_end,
    time_end: time_end,
  }
  if (name && name.length) {
    dataBody.name = name
  }
  if (agency && agency.length) {
    dataBody.agency = agency
  }
  if (location && location.length) {
    dataBody.location = location
  }
  if (salary && salary.length) {
    dataBody.salary = salary
  }

  return axios.put(Url.END_POINT + "/api/update_event",
    dataBody,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { uuid } } = data;
  return axios.delete(Url.END_POINT + "/api/delete_event",
    {
      data: {
        uuid_event: uuid
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncRestore(data) {
  const { data: { uuid } } = data;

  return axios.put(Url.END_POINT + "/api/restore_event",
    {
      uuid_event: uuid,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncExport(data) {
  let { data: { uuid, date_start, date_end } } = data;

  if (!uuid) {
    uuid = null
  }

  if (!date_start) {
    date_start = null
  }
  if (!date_end) {
    date_end = null
  }

  return axios.post(`${Url.END_POINT}/api/event/export`,
    {
      uuid,
      date_start,
      date_end,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDestroy(data) {
  const { data: { uuid } } = data;
  return axios.put(Url.END_POINT + "/api/update_close_event",
    {
      uuid_event: uuid,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListLive(data) {
  return axios.get(Url.END_POINT + "/api/get_all_events_id_name",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListAccount(data) {
  const { data: { event_uuid } } = data
  return axios.get(Url.END_POINT + "/api/get_all_user_event/" + event_uuid,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdateAccount(data) {
  const { data: { user_id, event_uuid } } = data;
  return axios.put(Url.END_POINT + "/api/update_user_event",
    {
      user_id, //[]
      event_uuid,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGlobalSearch(data) {
  const { data: { keyword } } = data;
  return axios.post(Url.END_POINT + "/api/get_search_event_and_product",
    {
      key: keyword
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncActive(data) {
  const { data: { uuid_event } } = data;
  return axios.put(Url.END_POINT + "/api/event_active",
    {
      uuid_event,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDone(data) {
  const { data: { uuid_event } } = data;
  return axios.put(Url.END_POINT + "/api/event_done",
    {
      uuid_event,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncEventCheckin(data) {
  const { data: { event_uuid, full_name, phone, email, identity_card } } = data;
  return axios.post(Url.END_POINT + "/api/event/checkin",
    {
      event_uuid, full_name, phone, email, identity_card,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Events } } = response.data;
      result.data = Events;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAsync(result))
}
function* actionListProduct(data) {
  const response = yield call(asyncListProduct, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product_in_event } } = response.data;
      result.data = Product_in_event;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListProductAsync(result))
}
function* actionListProductTemp(data) {
  const response = yield call(asyncListProductTemp, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Product_in_event } } = response.data;
      result.data = Product_in_event;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListProductTempAsync(result))
}
function* actionUpdateProductTemp(data) {
  const response = yield call(asyncUpdateProductTemp, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateProductAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionRestore(data) {
  const response = yield call(asyncRestore, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.RestoreAsync(result))
}
function* actionExport(data) {
  const response = yield call(asyncExport, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.data = Message;
    } else {
      result.data = "";

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ExportAsync(result))
}
function* actionDestroy(data) {
  const response = yield call(asyncDestroy, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DestroyAsync(result))
}
function* actionListLive(data) {
  const response = yield call(asyncListLive, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Events } } = response.data;
      result.data = Events;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListLiveAsync(result))
}
function* actionListAccount(data) {
  const response = yield call(asyncListAccount, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Events } } = response.data;
      result.data = Events;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAccountAsync(result))
}
function* actionUpdateAccount(data) {
  const response = yield call(asyncUpdateAccount, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAccountAsync(result))
}
function* actionGlobalSearch(data) {
  const response = yield call(asyncGlobalSearch, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Result } } = response.data;
      result.data = Result;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GlobalSearchAsync(result))
}
function* actionActive(data) {
  const response = yield call(asyncActive, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  // console.log(result); return;
  yield put(actions.ActiveAsync(result))
}
function* actionDone(data) {
  const response = yield call(asyncDone, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  // console.log(result); return;
  yield put(actions.DoneAsync(result))
}
function* actionEventCheckin(data) {
  const response = yield call(asyncEventCheckin, data);

  // console.log(response); //return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }
  }
  // console.log(result); return;
  yield put(actions.EventCheckinAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.EVENT_CREATE, actionCreate)
  yield takeLatest(TYPE.EVENT_LIST, actionList)
  yield takeLatest(TYPE.EVENT_LIST_PRODUCT, actionListProduct)
  yield takeLatest(TYPE.EVENT_LIST_PRODUCT_TEMP, actionListProductTemp)
  yield takeLatest(TYPE.EVENT_UPDATE_PRODUCT_TEMP, actionUpdateProductTemp)
  yield takeLatest(TYPE.EVENT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.EVENT_DELETE, actionDelete)
  yield takeLatest(TYPE.EVENT_UPDATE_RESTORE, actionRestore)
  yield takeLatest(TYPE.EVENT_EXPORT, actionExport)
  yield takeLatest(TYPE.EVENT_UPDATE_DESTROY, actionDestroy)
  yield takeLatest(TYPE.EVENT_LIST_LIVE, actionListLive)
  yield takeLatest(TYPE.EVENT_LIST_ACCOUNT, actionListAccount)
  yield takeLatest(TYPE.EVENT_UPDATE_ACCOUNT, actionUpdateAccount)
  yield takeLatest(TYPE.GLOBAL_SEARCH, actionGlobalSearch)
  yield takeLatest(TYPE.EVENT_ACTIVE, actionActive)
  yield takeLatest(TYPE.EVENT_DONE, actionDone)
  yield takeLatest(TYPE.EVENT_CHECKIN, actionEventCheckin)
}

export default CustomSaga