import * as TYPE from './types'

// CRUD
const dispatchProductCategoryCreateAsync = (data) => ({
  type: TYPE.PRODUCT_CATEGORY_CREATE,
  data,
});
const dispatchProductCategoryListAsync = () => ({
  type: TYPE.PRODUCT_CATEGORY_LIST,
});
const dispatchProductCategoryUpdateAsync = (data) => ({
  type: TYPE.PRODUCT_CATEGORY_UPDATE,
  data
});
const dispatchProductCategoryDeleteAsync = (data) => ({
  type: TYPE.PRODUCT_CATEGORY_DELETE,
  data
});
export default {
  dispatchProductCategoryCreateAsync,
  dispatchProductCategoryListAsync,
  dispatchProductCategoryUpdateAsync,
  dispatchProductCategoryDeleteAsync
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_CATEGORY_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_CATEGORY_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_CATEGORY_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_CATEGORY_DELETE_ASYNC,
    payload
  }
} 
