import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import {RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncCheckin(data) {
  const { data: {product_uuid, event_uuid, note_text, note_img_path}} = data
  return axios.post(Url.END_POINT + "/api/checkin_product",
    {
      product_uuid, 
      event_uuid, 
      note_text, 
      note_img_path
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCheckout(data) {
  const { data: {product_uuid, event_uuid, note_text, note_img_path}} = data
  return axios.put(Url.END_POINT + "/api/checkout_product",
    {
      product_uuid, 
      event_uuid, 
      note_text, 
      note_img_path
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

function asyncGetCount(data) {
  return axios.get(Url.END_POINT + "/api/get_count_dashboard",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')

      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncGetDetail(data) {
  const { data: { uuid } } = data;
  return axios.get(Url.END_POINT + "/api/get_product/" + uuid,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreate(data) {
  const { data: { name, product_category_id, product_status_id, total, image, period_date, period_time } } = data;
  let formData = new FormData();
  formData.append("name", name);
  formData.append("product_category_id", product_category_id);
  formData.append("product_status_id", product_status_id);
  formData.append("total", total);
  formData.append("image", image);
  formData.append("period_date", period_date);
  formData.append("period_time", period_time);

  return axios.post(Url.END_POINT + "/api/product",
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { data: { product_uuid, repair_count, income_stock_date, income_stock_time, name, product_category_id, product_status_id, image, period_date, period_time } } = data;
  let formData = new FormData();
  formData.append("product_uuid", product_uuid);
  formData.append("name", name);
  formData.append("product_category_id", product_category_id);
  formData.append("product_status_id", product_status_id);
  formData.append("period_date", period_date);
  formData.append("period_time", period_time);
  formData.append("repair_count", repair_count);
  formData.append("income_stock_date", income_stock_date);
  formData.append("income_stock_time", income_stock_time);
  formData.append("_method", "PUT");

  if (image !== undefined) {
    formData.append("image", image);
  }

  return axios.post(Url.END_POINT + "/api/update_product",
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList(data) {
  return axios.get(Url.END_POINT + "/api/get_products_in_out",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { data: { uuid_product}} = data;
  return axios.delete(Url.END_POINT + "/api/product",
    {
      data: {
        uuid_product: uuid_product
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncVerifyNote(data) {
  const { data: { event_uuid, product_uuid } } = data;
  return axios.put(Url.END_POINT + "/api/confirm_success_note",
    {
      event_uuid,
      product_uuid,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionCheckin(data) {
  const response = yield call(asyncCheckin, data);

  // console.log(response); return false;
  
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 404 || response.status === 422 || response.status === 403) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    // if (response.status !== 200) {
    //   console.log(response);
    // }
  }
  // console.log(result); return false;
  yield put(actions.QRCodeCheckinAsync(result))
}
function* actionCheckout(data) {
  const response = yield call(asyncCheckout, data);

  // console.log(response); return false;

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 404 || response.status === 422 || response.status === 403) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
    // if (response.status !== 200) {
    //   console.log(response);
    // }
  }
  yield put(actions.QRCodeCheckoutAsync(result))
}

function* actionGetCount(data) {
  const response = yield call(asyncGetCount, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Count_dashboard } } = response.data;
      result.data = Count_dashboard;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetCountAsync(result))
}
function* actionGetDetail(data) {
  const response = yield call(asyncGetDetail, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    let { Notification: { Message } } = response.data;
    result.message = Message;

    if (response.status === 200) {
      let { Notification: { Product } } = response.data;
      result.data = Product;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.GetDetailAsync(result))
}
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionList(data) {
  const response = yield call(asyncList, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Products } } = response.data;
      result.data = Products;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  // console.log(result); return;
  yield put(actions.ListAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }
    
    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionVerifyNote(data) {
  const response = yield call(asyncVerifyNote, data);

  // console.log(response); 
  // return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.VerifyNoteAsync(result))
}
/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.QRCODE_CHECKIN, actionCheckin)
  yield takeLatest(TYPE.QRCODE_CHECKOUT, actionCheckout)

  yield takeLatest(TYPE.PRODUCT_GET_DETAIL, actionGetDetail)
  yield takeLatest(TYPE.PRODUCT_GET_COUNT, actionGetCount)
  yield takeLatest(TYPE.PRODUCT_CREATE, actionCreate)
  yield takeLatest(TYPE.PRODUCT_LIST, actionList)
  yield takeLatest(TYPE.PRODUCT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.PRODUCT_DELETE, actionDelete)

  yield takeLatest(TYPE.PRODUCT_VERIFY_NOTE, actionVerifyNote)
}

export default CustomSaga