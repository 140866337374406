
const data = [
    {
        id: "dashboards",
        icon: "home",
        label: "app.dashboards",
        to: "/app/dashboards/default",
        title: "",
        minPermission: 1,
        isLeaf: false,
    },
    {
        id: "events",
        icon: undefined,
        label: "app.events",
        to: "/app/events/default",
        title: "Sự kiện",
        minPermission: 1,
        isLeaf: false,
    },
    {
        id: "products",
        icon: undefined,
        label: "app.products",
        to: "/app/products",
        title: "Thiết bị",
        subName: "menuProduct",
        isLeaf: false,
        subs: [
            {
                icon: undefined,
                label: "app.products.list",
                to: "/app/products/list",
                title: "Danh sách thiết bị",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.products.status",
                to: "/app/products/status",
                title: "Trạng thái",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.products.category",
                to: "/app/products/category",
                title: "Loại thiết bị",
                minPermission: 1,
                isLeaf: true,
            },
        ]
    },
    {
        id: "warehouse",
        icon: undefined,
        label: "app.warehouse",
        to: "/app/warehouse",
        title: "Quản lý kho",
        subName: "menuWarehouse",
        subs: [
            {
                icon: undefined,
                label: "app.warehouse.come",
                to: "/app/warehouse/come",
                title: "Nhập xuất",
                minPermission: 2,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.warehouse.checkout",
                to: "/app/warehouse/out",
                title: "Bên ngoài",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.warehouse.checkin",
                to: "/app/warehouse/in",
                title: "Trong kho",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.warehouse.outofdate",
                to: "/app/warehouse/outofdate",
                title: "Hết hạn bảo hành",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.warehouse.status",
                to: "/app/warehouse/status",
                title: "Tình trạng",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.warehouse.deleted",
                to: "/app/warehouse/deleted",
                title: "Đã bỏ đi",
                minPermission: 1,
                isLeaf: true,
            },
            {
                icon: undefined,
                label: "app.warehouse.news",
                to: "/app/warehouse/news",
                title: "Mới nhập",
                minPermission: 1,
                isLeaf: true,
            },
        ]
    },
    {
        id: "accounts",
        icon: undefined,
        label: "app.accounts",
        to: "/app/accounts/default",
        title: "Người dùng",
        minPermission: 1,
        isLeaf: false,
    },
    {
        id: "chat",
        icon: undefined,
        label: "app.chat",
        to: "/app/chat/default",
        title: "Tin nhắn",
        minPermission: 2,
        isLeaf: false,
    },
];
export default data;
