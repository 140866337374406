import * as TYPE from './types'

// CRUD
const dispatchChatListConversationAsync = (data) => ({
  type: TYPE.CHAT_LIST_CONVERSATION,
  data,
});
const dispatchChatListConversationPendingAsync = (data) => ({
  type: TYPE.CHAT_LIST_CONVERSATION_PENDING,
  data,
});
const dispatchChatListConversationFriendAsync = (data) => ({
  type: TYPE.CHAT_LIST_FRIEND,
  data,
});
const dispatchChatCreateMessageAsync = (data) => ({
  type: TYPE.CHAT_CREATE_MESSAGE,
  data,
});
const dispatchChatListMessageAsync = (data) => ({
  type: TYPE.CHAT_LIST_MESSAGE,
  data,
});
export default {
  dispatchChatListConversationAsync,
  dispatchChatListConversationPendingAsync,
  dispatchChatListConversationFriendAsync,
  dispatchChatCreateMessageAsync,
  dispatchChatListMessageAsync,
};

// action 
export const ListConversationAsync = (payload) => {
  return {
    type: TYPE.CHAT_LIST_CONVERSATION_ASYNC,
    payload
  }
} 
export const ListConversationPendingAsync = (payload) => {
  return {
    type: TYPE.CHAT_LIST_CONVERSATION_PENDING_ASYNC,
    payload
  }
} 
export const ListConversationFriendAsync = (payload) => {
  return {
    type: TYPE.CHAT_LIST_FRIEND_ASYNC,
    payload
  }
} 
export const CreateMessageAsync = (payload) => {
  return {
    type: TYPE.CHAT_CREATE_MESSAGE_ASYNC,
    payload
  }
} 
export const ListMessageAsync = (payload) => {
  return {
    type: TYPE.CHAT_LIST_MESSAGE_ASYNC,
    payload
  }
} 
