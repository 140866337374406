import * as TYPE from './types'

const initialState = {
  token: localStorage.getItem('token'),
  messageLogin: "",
  statusLogin: 200,
  messageUpdateMe: "",
  statusUpdateMe: 200,
  data: [],
  message: "",
  status: 500,
  online: 0,
}

function AccountReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.ACCOUNT_LOGIN_ASYNC:
      return {
        ...state,
        token: action.payload.token,
        messageLogin: action.payload.message,
        statusLogin: action.payload.status,
      }
    case TYPE.ACCOUNT_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.ACCOUNT_UPDATE_ME_ASYNC:
      return {
        ...state,
        messageUpdateMe: action.payload.message,
        statusUpdateMe: action.payload.status,
      }
    case TYPE.ACCOUNT_COUNT_ONLINE_ASYNC:
      return {
        ...state,
        online: action.payload.data,
      }
    default:
      return state
  }
}

export default AccountReducer