import * as TYPE from './types'

const initialState = {
  conversation: [],
  conversation_pending: [],
  conversation_friend: [],
  messages: [],
  message: "",
  status: 200,
}

function ChatReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.CHAT_LIST_CONVERSATION_ASYNC:
      return {
        ...state,
        conversation: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.CHAT_LIST_CONVERSATION_PENDING_ASYNC:
      return {
        ...state,
        conversation_pending: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.CHAT_LIST_FRIEND_ASYNC:
      return {
        ...state,
        conversation_friend: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.CHAT_LIST_MESSAGE_ASYNC:
      return {
        ...state,
        messages: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.CHAT_CREATE_MESSAGE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default ChatReducer