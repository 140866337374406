// LIST
export const WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST = "WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST"
export const WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST_ASYNC = "WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST_ASYNC"
export const WAREHOUSE_PRODUCT_OUTSIDE_LIST = "WAREHOUSE_PRODUCT_OUTSIDE_LIST"
export const WAREHOUSE_PRODUCT_OUTSIDE_LIST_ASYNC = "WAREHOUSE_PRODUCT_OUTSIDE_LIST_ASYNC"
export const WAREHOUSE_PRODUCT_INSTOCK_LIST = "WAREHOUSE_PRODUCT_INSTOCK_LIST"
export const WAREHOUSE_PRODUCT_INSTOCK_LIST_ASYNC = "WAREHOUSE_PRODUCT_INSTOCK_LIST_ASYNC"
export const WAREHOUSE_PRODUCT_OUTOFDATE_LIST = "WAREHOUSE_PRODUCT_OUTOFDATE_LIST"
export const WAREHOUSE_PRODUCT_OUTOFDATE_LIST_ASYNC = "WAREHOUSE_PRODUCT_OUTOFDATE_LIST_ASYNC"
export const WAREHOUSE_PRODUCT_USED_LIST = "WAREHOUSE_PRODUCT_USED_LIST"
export const WAREHOUSE_PRODUCT_USED_LIST_ASYNC = "WAREHOUSE_PRODUCT_USED_LIST_ASYNC"
export const WAREHOUSE_PRODUCT_NEW_LIST = "WAREHOUSE_PRODUCT_NEW_LIST"
export const WAREHOUSE_PRODUCT_NEW_LIST_ASYNC = "WAREHOUSE_PRODUCT_NEW_LIST_ASYNC"

// CRUD
export const WAREHOUSE_PRODUCT_DELETED_LIST = "WAREHOUSE_PRODUCT_DELETED_LIST"
export const WAREHOUSE_PRODUCT_DELETED_LIST_ASYNC = "WAREHOUSE_PRODUCT_DELETED_LIST_ASYNC"
export const WAREHOUSE_PRODUCT_DELETED_UPDATE = "WAREHOUSE_PRODUCT_DELETED_UPDATE"
export const WAREHOUSE_PRODUCT_DELETED_UPDATE_ASYNC = "WAREHOUSE_PRODUCT_DELETED_UPDATE_ASYNC"
export const WAREHOUSE_PRODUCT_DELETED_DELETE = "WAREHOUSE_PRODUCT_DELETED_DELETE"
export const WAREHOUSE_PRODUCT_DELETED_DELETE_ASYNC = "WAREHOUSE_PRODUCT_DELETED_DELETE_ASYNC"
export const WAREHOUSE_PRODUCT_DELETED_CREATE = "WAREHOUSE_PRODUCT_DELETED_CREATE"
export const WAREHOUSE_PRODUCT_DELETED_CREATE_ASYNC = "WAREHOUSE_PRODUCT_DELETED_CREATE_ASYNC"