import * as TYPE from './types'

// CRUD
const dispatchProductStatusCreateAsync = (data) => ({
  type: TYPE.PRODUCT_STATUS_CREATE,
  data,
});
const dispatchProductStatusListAsync = () => ({
  type: TYPE.PRODUCT_STATUS_LIST,
});
const dispatchProductStatusUpdateAsync = (data) => ({
  type: TYPE.PRODUCT_STATUS_UPDATE,
  data,
});
const dispatchProductStatusDeleteAsync = (data) => ({
  type: TYPE.PRODUCT_STATUS_DELETE,
  data,
});
export default {
  dispatchProductStatusCreateAsync,
  dispatchProductStatusListAsync,
  dispatchProductStatusUpdateAsync,
  dispatchProductStatusDeleteAsync,
};

// action 
export const ListAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_STATUS_LIST_ASYNC,
    payload
  }
} 
export const CreateAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_STATUS_CREATE_ASYNC,
    payload
  }
} 
export const UpdateAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_STATUS_UPDATE_ASYNC,
    payload
  }
} 
export const DeleteAsync = (payload) => {
  return {
    type: TYPE.PRODUCT_STATUS_DELETE_ASYNC,
    payload
  }
} 
