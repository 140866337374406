import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { setPermission, RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncLogin(data) {
  const { user: { user_name, password } } = data;
  return axios.post(Url.END_POINT + "/login",
    {
      user_name,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreate(data) {
  const { user: { user_name, email, password, address, confirmPassword, full_name, permission } } = data;
  return axios.post(Url.END_POINT + "/api/create_user",
    {
      user_name,
      full_name,
      email,
      address,
      password,
      confirmPassword,
      permission,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdate(data) {
  const { user: { id, email, password, confirmPassword, address, full_name, permission } } = data;
  let dataBody = {
    user_id: id,
  };

  if (email && email.length) {
    dataBody.email = email
  }
  if (password && password.length) {
    dataBody.password = password
    dataBody.confirmPassword = confirmPassword
  }
  if (address && address.length) {
    dataBody.address = address
  }
  if (full_name && full_name.length) {
    dataBody.full_name = full_name
  }
  if (permission) {
    dataBody.permission = permission
  }

  return axios.put(Url.END_POINT + "/api/update_user", dataBody,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncList() {
  return axios.get(Url.END_POINT + "/api/get_all_user",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncDelete(data) {
  const { user: { id } } = data;
  return axios.delete(Url.END_POINT + "/api/delete_user",
    {
      data: {
        user_id: id
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncUpdateMe(data) {
  const { user: { email, full_name, address, image, oldPassword, password, confirmPassword } } = data;

  let formData = new FormData();
  if (email && email.length) {
    formData.append("email", email);
  }
  if (full_name && full_name.length) {
    formData.append("full_name", full_name);
  }
  if (email && email.length) {
    formData.append("address", address);
  }
  if (image && image != null) {
    formData.append("image", image);
  }
  if (oldPassword !== "" && password !== "" && confirmPassword !== "") {
    formData.append("oldPassword", oldPassword);
    formData.append("password", password);
    formData.append("confirmPassword", confirmPassword);
  }
  formData.append("_method", "PUT");

  return axios.post(Url.END_POINT + "/api/update_user_me",
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCountOnline() {
  return axios.get(Url.END_POINT + "/api/get_all_user_online",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}

/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionLogin(data) {
  const response = yield call(asyncLogin, data);
  // console.log(response); return;

  // handler
  let result = {}
  result.message = "Tài khoản hoặc mật khẩu không chính xác";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { Token } } = response.data;
      let { Notification: { User: { id, id_permission, user_name, full_name, email, avatar_img_path, address, name, view_only } } } = response.data;

      result.token = Token;
      localStorage.setItem("token", Token);
      localStorage.setItem("user_id", id);
      localStorage.setItem("user_name", user_name);
      localStorage.setItem("full_name", full_name);
      localStorage.setItem("email", email);
      localStorage.setItem("avatar_img_path", avatar_img_path);
      localStorage.setItem("address", address);
      localStorage.setItem("permissionName", name);
      localStorage.setItem("z", setPermission(id_permission, view_only));

      // router
      const { history } = data;
      history.push('/app/dashboards/default');
    }
  }
  // console.log(result); return;
  yield put(actions.LoginAsync(result))
}
function* actionLogout(data) {

  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_name");
  localStorage.removeItem("full_name");
  localStorage.removeItem("email");
  localStorage.removeItem("avatar_img_path");
  localStorage.removeItem("address");
  localStorage.removeItem("permissionName");
  localStorage.removeItem("z");

  // router
  document.location = '/';
}
function* actionCreate(data) {
  const response = yield call(asyncCreate, data);

  // console.log(response);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.CreateAsync(result))
}
function* actionList() {
  const response = yield call(asyncList);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { User } } = response.data;
      result.data = User;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListAsync(result))
}
function* actionUpdate(data) {
  const response = yield call(asyncUpdate, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.UpdateAsync(result))
}
function* actionDelete(data) {
  const response = yield call(asyncDelete, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  yield put(actions.DeleteAsync(result))
}
function* actionUpdateMe(data) {
  const response = yield call(asyncUpdateMe, data);

  // console.log(response); return false;

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 200) {
      let { Notification: { User } } = response.data;
      localStorage.setItem("full_name", User.full_name)
      localStorage.setItem("email", User.email)
      localStorage.setItem("address", User.address)
      localStorage.setItem("avatar_img_path", User.avatar_img_path)
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  // console.log(response);

  yield put(actions.UpdateMeAsync(result))
}

function* actionCountOnline(data) {
  const response = yield call(asyncCountOnline, data); //get_all_user_online

  // console.log(response); return false;

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;

    if (response.status === 200) {
      let { Notification: { User_online } } = response.data;
      result.data = User_online;

    }

    if (response.status === 401) {
      RefreshAuthorization();
    }
  }
  // console.log(result);

  yield put(actions.CountOnlineAsync(result))
}


/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.ACCOUNT_LOGIN, actionLogin)
  yield takeLatest(TYPE.ACCOUNT_LOGOUT, actionLogout)
  yield takeLatest(TYPE.ACCOUNT_CREATE, actionCreate)
  yield takeLatest(TYPE.ACCOUNT_UPDATE, actionUpdate)
  yield takeLatest(TYPE.ACCOUNT_LIST, actionList)
  yield takeLatest(TYPE.ACCOUNT_DELETE, actionDelete)
  yield takeLatest(TYPE.ACCOUNT_UPDATE_ME, actionUpdateMe)
  yield takeLatest(TYPE.ACCOUNT_COUNT_ONLINE, actionCountOnline)
}


export default CustomSaga