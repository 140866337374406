import React, { useEffect, useState } from 'react'
import {
  ConnectedComponent as connect
} from '../../constants'
const Marquee = props => {
  const [message, setMessage] = useState("Chưa có sự kiện");

  useEffect(() => {
    props.dispatchEventListAsync();
  }, [])

  useEffect(() => {
    if (props.events.data.length) {
      let currentdata = props.events.data.filter(d => d.date_time_start <= d.date_time_now && d.is_closed === 1 && d.date_time_end >= d.date_time_now)
      let message = currentdata.map(u => u.name).join(' | ');
      if (currentdata.length > 0) {
        setMessage(message);
      }
    }
  }, [props.events.data])

  return (
    <div className="marquee-container">
      <marquee>Các sự kiện đang diễn ra: <b>{message}</b></marquee>
    </div>
  )
}

export default connect(Marquee);