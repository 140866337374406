export const ACCOUNT_LOGIN = "ACCOUNT_LOGIN"
export const ACCOUNT_LOGIN_ASYNC = "ACCOUNT_LOGIN_ASYNC"
export const ACCOUNT_LOGOUT = "ACCOUNT_LOGOUT"
export const ACCOUNT_LOGOUT_ASYNC = "ACCOUNT_LOGOUT_ASYNC"

// CRUD PERMISSION
export const ACCOUNT_CREATE = "ACCOUNT_CREATE"
export const ACCOUNT_CREATE_ASYNC = "ACCOUNT_CREATE_ASYNC"
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE"
export const ACCOUNT_UPDATE_ASYNC = "ACCOUNT_UPDATE_ASYNC"
export const ACCOUNT_LIST = "ACCOUNT_LIST"
export const ACCOUNT_LIST_ASYNC = "ACCOUNT_LIST_ASYNC"
export const ACCOUNT_DELETE = "ACCOUNT_DELETE"
export const ACCOUNT_DELETE_ASYNC = "ACCOUNT_DELETE_ASYNC"

// me 
export const ACCOUNT_UPDATE_ME = "ACCOUNT_UPDATE_ME"
export const ACCOUNT_UPDATE_ME_ASYNC = "ACCOUNT_UPDATE_ME_ASYNC"

// online
export const ACCOUNT_COUNT_ONLINE = "ACCOUNT_COUNT_ONLINE"
export const ACCOUNT_COUNT_ONLINE_ASYNC = "ACCOUNT_COUNT_ONLINE_ASYNC"