import * as TYPE from './types'

const initialState = {
  data: [],
  message: "",
  status: 200,
}

function StatusReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PRODUCT_STATUS_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_STATUS_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_STATUS_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_STATUS_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default StatusReducer