import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import * as actions from './actions'
import { Url } from '../../constants'
import { RefreshAuthorization } from '../../helpers/Utils'

/*********************
 *                   *
 *  axios to api     *
 *                   *
 *********************/
function asyncListConversation(data) {
  return axios.get(Url.END_POINT + "/api/conversation",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListConversationPending(data) {
  return axios.get(Url.END_POINT + "/api/conversation/pending",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListConversationFriend() {
  return axios.get(Url.END_POINT + "/api/conversation/friend/select",
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncCreateMessage(data) {
  const { data: { pin, body, type } } = data;
  return axios.post(Url.END_POINT + "/api/message",
    {
      pin, body, type
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}
function asyncListMessage(data) {
  const { data: { pin, offset } } = data;
  return axios.get(Url.END_POINT + "/api/messages/" + pin + "?offset=" + offset,
    {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem('token')
      }
    })
    .then(res => res)
    .catch(err => err.response)
}


/*********************
 *                   *
 *  TO ACTION        *
 *                   *
 *********************/
function* actionListConversation(data) {
  const response = yield call(asyncListConversation, data);

  // console.log(response);
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message, Conversation } } = response.data;
      result.message = Message;
      result.data = Conversation;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.ListConversationAsync(result))
}
function* actionListConversationPending(data) {
  const response = yield call(asyncListConversationPending, data);

  // console.log(response);return;
  let result = {}
  result.message = "Có lỗi xảy ra";

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;

    if (response.status === 200) {
      let { Notification: { Message, ConversationPending } } = response.data;
      result.message = Message;
      result.data = ConversationPending;
    } else {
      result.data = [];

      if (response.status === 401) {
        RefreshAuthorization();
      }
    }
  }
  yield put(actions.ListConversationPendingAsync(result))
}
function* actionListConversationFriend(data) {
  const response = yield call(asyncListConversationFriend, data);

  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message, ConversationFriend } } = response.data;
      result.message = Message;
      result.data = ConversationFriend;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.ListConversationFriendAsync(result))
}
function* actionCreateMessage(data) {
  const response = yield call(asyncCreateMessage, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message } } = response.data;
      result.message = Message;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.CreateMessageAsync(result))
}
function* actionListMessage(data) {
  const response = yield call(asyncListMessage, data);

  // console.log(response); return;
  // handler
  let result = {}
  result.message = "Có lỗi xảy ra";
  result.status = 422;

  if (response) {
    let { statusText } = response.data;
    result.message = statusText;
    result.status = response.status;
    if (response.status === 200 || response.status === 403 || response.status === 404 || response.status === 422) {
      let { Notification: { Message, Messages } } = response.data;
      result.message = Message;
      result.data = Messages;
    }

    if (response.status === 401) {
      RefreshAuthorization();
    }

    if (response.status !== 200) {
      console.log(response);
    }
  }
  yield put(actions.ListMessageAsync(result))
}

/*********************
 *                   *
 *  CUSTOM run SAGA  *
 *                   *
 *********************/
import { takeLatest, takeEvery } from 'redux-saga/effects'
import "@babel/polyfill"
import * as TYPE from './types'

function* CustomSaga() {
  yield takeLatest(TYPE.CHAT_LIST_CONVERSATION, actionListConversation)
  yield takeLatest(TYPE.CHAT_LIST_CONVERSATION_PENDING, actionListConversationPending)
  yield takeLatest(TYPE.CHAT_LIST_FRIEND, actionListConversationFriend)
  yield takeLatest(TYPE.CHAT_CREATE_MESSAGE, actionCreateMessage)
  yield takeLatest(TYPE.CHAT_LIST_MESSAGE, actionListMessage)
}

export default CustomSaga