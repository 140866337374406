import * as TYPE from './types'

let result = [];

const initialState = {
  data: [],
  message: "",
  status: 200,
}

function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PRODUCT_CATEGORY_LIST_ASYNC:
      return {
        ...state,
        data: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_CATEGORY_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_CATEGORY_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.PRODUCT_CATEGORY_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    default:
      return state
  }
}

export default CategoryReducer