// CRUD
export const CHAT_LIST_FRIEND = "CHAT_LIST_FRIEND"
export const CHAT_LIST_FRIEND_ASYNC = "CHAT_LIST_FRIEND_ASYNC"
export const CHAT_LIST_CONVERSATION = "CHAT_LIST_CONVERSATION"
export const CHAT_LIST_CONVERSATION_ASYNC = "CHAT_LIST_CONVERSATION_ASYNC"
export const CHAT_LIST_CONVERSATION_PENDING = "CHAT_LIST_CONVERSATION"
export const CHAT_LIST_CONVERSATION_PENDING_ASYNC = "CHAT_LIST_CONVERSATION_PENDING_ASYNC"
export const CHAT_CREATE_MESSAGE = "CHAT_CREATE_MESSAGE"
export const CHAT_CREATE_MESSAGE_ASYNC = "CHAT_CREATE_MESSAGE_ASYNC"
export const CHAT_LIST_MESSAGE = "CHAT_LIST_MESSAGE"
export const CHAT_LIST_MESSAGE_ASYNC = "CHAT_LIST_MESSAGE_ASYNC"