import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Nav,
  Dropdown,
  Icon,
} from 'rsuite'
import classnames from 'classnames'
import Hamburger from './Hamburger'
import menuItems from './menu'
import { getPermissionCode } from '../../helpers/Utils'
import { ConnectedComponent as connect } from '../../constants'
import { Howl } from 'howler';

var alert_audio = new Howl({
  src: [require('../../assets/sound/alert.mp3')]
});

const CustomNav = (props) => {
  const [active, setActive] = useState("analytics");

  const [state, setState] = useState({
    permission: getPermissionCode(),
    pathname: "",
  })

  useEffect(
    () => {
      props.dispatchInitRealtime();

      setState({
        ...state,
        pathname: window.location.pathname
      })
    }, []);

  /** SocketIO */
  useEffect(
    () => {
      props.realtime.socket.on('new_message', (data) => {
        alert_audio.play();
        props.dispatchUpdateRealtime();
      })
      props.realtime.socket.on('disconnect', () => {
        props.dispatchInitRealtime();
      });
    }, [props.realtime.socket])
  /** END SocketIO */


  const onSelect = (activeKey) => {
    setActive(activeKey)
  }

  return (
    <section className="nav__container">
      <section className="banner">
        <ul className="background">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </section>
      <Hamburger
        i18n={props.i18n}
      />
      <section className='custom-nav'>
        <Nav
          className="custom-nav--desktop"
          appearance="tabs"
          activeKey={active}
        >
          {menuItems.map((item, idx) => {
            return (
              item.subs
                ?
                <Dropdown
                  active={item.to === state.pathname}
                  componentClass={"span"}
                  key={idx}
                  title={item.title}>
                  {item.subs.map(sub => {
                    return (
                      state.permission >= sub.minPermission
                      && <NavLink to={sub.to} key={sub.label}>
                        <Dropdown.Item
                          onSelect={() => {
                            setState({
                              ...state,
                              pathname: item.to
                            })
                          }}
                          componentClass="span">
                          {sub.title}
                        </Dropdown.Item>
                      </NavLink>
                    )
                  })}
                </Dropdown>
                :
                state.permission >= item.minPermission && <NavLink to={item.to} key={idx}>
                  <Nav.Item
                    onSelect={() => {
                      setState({
                        ...state,
                        pathname: item.to
                      })
                    }}
                    icon={item.icon !== undefined ? <Icon icon={item.icon} size="lg" /> : null}
                    componentClass="span">
                    {item.title}
                  </Nav.Item>
                </NavLink>
            )
          })}
        </Nav>
      </section>
    </section>
  )
}

export default connect(CustomNav);