import { Url } from '../constants'
import io from 'socket.io-client'

export const WsInitState = () => {
    // console.log("init");
    
    const conn = io(Url.END_POINT_SOCKETIO, {
        'reconnection': true,
        'reconnectionDelay': 500,
        'reconnectionAttempts': 10
      });

    // return connection
    return conn;
}

export const WsRegister = (socket) => {
  // console.log("join room");
  
  if (localStorage.getItem('user_id')) {
    socket.emit('register', localStorage.getItem('user_id'));

    return true;
  }
  return false;
}
