import * as TYPE from './types'
// List
const dispatchWareHouseOutsideListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_OUTSIDE_LIST,
});
const dispatchWareHouseInstockListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_INSTOCK_LIST,
});
const dispatchWareHouseOutofdateListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_OUTOFDATE_LIST,
});
const dispatchWareHouseUsedListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_USED_LIST,
});
const dispatchWareHouseNewListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_NEW_LIST,
});
const dispatchWareHousePUETListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST,
});

// CRUD
const dispatchWareHouseDeletedListAsync = () => ({
  type: TYPE.WAREHOUSE_PRODUCT_DELETED_LIST,
});
const dispatchWareHouseDeletedCreateAsync = (data) => ({
  type: TYPE.WAREHOUSE_PRODUCT_DELETED_CREATE,
  data,
});
const dispatchWareHouseDeletedUpdateAsync = (data) => ({
  type: TYPE.WAREHOUSE_PRODUCT_DELETED_UPDATE,
  data,
});
const dispatchWareHouseDeletedDeleteAsync = (data) => ({
  type: TYPE.WAREHOUSE_PRODUCT_DELETED_DELETE,
  data,
});
export default {
  dispatchWareHouseOutsideListAsync,
  dispatchWareHouseInstockListAsync,
  dispatchWareHouseOutofdateListAsync,
  dispatchWareHouseUsedListAsync,
  dispatchWareHouseNewListAsync,
  dispatchWareHousePUETListAsync,

  dispatchWareHouseDeletedListAsync,
  dispatchWareHouseDeletedCreateAsync,
  dispatchWareHouseDeletedUpdateAsync,
  dispatchWareHouseDeletedDeleteAsync,
};

// action CRUD
export const DeletedListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_DELETED_LIST_ASYNC,
    payload
  }
} 
export const DeletedUpdateAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_DELETED_UPDATE_ASYNC,
    payload
  }
} 
export const DeletedCreateAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_DELETED_CREATE_ASYNC,
    payload
  }
} 
export const DeletedDeleteAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_DELETED_DELETE_ASYNC,
    payload
  }
} 

// action LIST
export const OutsideListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_OUTSIDE_LIST_ASYNC,
    payload
  }
} 
export const InstockListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_INSTOCK_LIST_ASYNC,
    payload
  }
} 
export const OutofdateListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_OUTOFDATE_LIST_ASYNC,
    payload
  }
} 
export const UsedListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_USED_LIST_ASYNC,
    payload
  }
}
export const NewListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_NEW_LIST_ASYNC,
    payload
  }
} 
export const PUETListAsync = (payload) => {
  return {
    type: TYPE.WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST_ASYNC,
    payload
  }
}