import * as TYPE from './types'

const initialState = {
  dataOutside: [],
  dataInstock: [],
  dataOutofdate: [],
  dataUsed: [],
  dataNew: [],
  dataPUET: [],
  dataDeleted: [],
  message: "",
  status: 200,
}

function WarehouseReducer(state = initialState, action) {
  switch (action.type) {
    // CRUD
    case TYPE.WAREHOUSE_PRODUCT_DELETED_LIST_ASYNC:
      return {
        ...state,
        dataDeleted: action.payload.data,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.WAREHOUSE_PRODUCT_DELETED_UPDATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.WAREHOUSE_PRODUCT_DELETED_CREATE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }
    case TYPE.WAREHOUSE_PRODUCT_DELETED_DELETE_ASYNC:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      }

    // LIST
    case TYPE.WAREHOUSE_PRODUCT_OUTSIDE_LIST_ASYNC:
      return {
        ...state,
        dataOutside: action.payload.data,
      }
    case TYPE.WAREHOUSE_PRODUCT_INSTOCK_LIST_ASYNC:
      return {
        ...state,
        dataInstock: action.payload.data,
      }
    case TYPE.WAREHOUSE_PRODUCT_OUTOFDATE_LIST_ASYNC:
      return {
        ...state,
        dataOutofdate: action.payload.data,
      }
    case TYPE.WAREHOUSE_PRODUCT_USED_LIST_ASYNC:
      return {
        ...state,
        dataUsed: action.payload.data,
      }
    case TYPE.WAREHOUSE_PRODUCT_NEW_LIST_ASYNC:
      return {
        ...state,
        dataNew: action.payload.data,
      }
    case TYPE.WAREHOUSE_PRODUCT_USER_EVENT_TIME_LIST_ASYNC:
      return {
        ...state,
        dataPUET: action.payload.data,
      }
    default:
      return state
  }
}

export default WarehouseReducer